import React, { useRef, useState } from "react";
import Layout from "../components/Layout";
import { Helmet } from "react-helmet";
import "../styles/360-catalog.scss";
import "../styles/auto.scss";
import "../styles/furniture.scss";
import VideoPlayer from "../components/VideoPlayer/VideoPlayer";
import CommonSection from "../components/CommonSection";
import CaseStudy from "../sections/homepage/CaseStudy";
import "bootstrap/dist/css/bootstrap.min.css";
import ClientLogoBanner from "../components/ClientLogoBanner";
import AccordionSection from "../components/AccordionSection";
import StatColumn from "../components/StatColumn";
import ButtonBanner from "../components/ButtonBanner";
import Testimonials from "../sections/homepage/Testimonials";
import Blogs from "../sections/homepage/Blogs/Blogs";
import { Container } from "react-bootstrap";
const Furniture = () => {
  const caseStudies = [
    {
      image: "../../images/casestudy/featherlite.webp",
      title:
        "Immersive experience for work from home furniture in just six weeks",
      footer: "",
      link: "/featherlite",
    },
    {
      image: "../../images/casestudy/wakefit.webp",
      title: "Feel the comfort virtually",
      footer: "",
      link: "/wakefit",
    },
    {
      image: "../../images/casestudy/guarented.webp",
      title: "A fun way of buying furniture",
      footer: "",
      link: "/guarented",
    },
  ];
  let logoList = [
    "../../images/trustedclients/featherlite1.svg",
    "../../images/trustedclients/wakefit1.svg",
    "../../images/trustedclients/atmosphere.svg",
    "../../images/trustedclients/danubehome1.svg",
    "../../images/trustedclients/kimisha.svg",
    "../../images/trustedclients/guarented.webp",
  ];
  const advantages = [
    {
      image: "../../images/furniture/augmented-reality-in-retail.png",
      imageWebp: "../../images/furniture/augmented-reality-in-retail.webp",
      title: "One-stop solution that helps you scale",
      sub: "Create, edit, and manage 3D product renders &  interactions in bulk within a few minutes.",
    },
    {
      image: "../../images/furniture/augmented-reality-in-retail.png",
      imageWebp: "../../images/furniture/augmented-reality-in-retail.webp",
      title: "Easy to use software",
      sub: "Our platform is intuitive and seamless; get started in seconds.",
    },
    {
      image: "../../images/furniture/augmented-reality-in-retail.png",
      imageWebp: "../../images/furniture/augmented-reality-in-retail.webp",
      title: "Easy integration",
      sub: "Our software can be installed seamlessly on your website in a few minutes.",
    },
    {
      image: "../../images/furniture/augmented-reality-in-retail.png",
      imageWebp: "../../images/furniture/augmented-reality-in-retail.webp",
      title: "Unbelievable speed",
      sub: "Our cloud servers helps integrate thousands of images in seconds.",
    },
    {
      image: "../../images/furniture/augmented-reality-in-retail.png",
      imageWebp: "../../images/furniture/augmented-reality-in-retail.webp",
      title: "Hassle-free distribution",
      sub: "Single click download of the 3D renders for multi platform distribution.",
    },
    {
      image: "../../images/furniture/augmented-reality-in-retail.png",
      imageWebp: "../../images/furniture/augmented-reality-in-retail.webp",
      title: "Sales intelligence",
      sub: "Get insights around the most viewed and interacted products to improve on your marketing strategy.",
    },
    {
      image: "../../images/furniture/augmented-reality-in-retail.png",
      imageWebp: "../../images/furniture/augmented-reality-in-retail.webp",
      title: "Works on all the devices",
      sub: "A user can use their systems, phones, or tablets to view and interact with products",
    },
    {
      image: "../../images/furniture/augmented-reality-in-retail.png",
      imageWebp: "../../images/furniture/augmented-reality-in-retail.webp",
      title: "24/7 support",
      sub: "We’ve a dedicated team to help you with all your queries.",
    },
  ];

  return (
    <Layout>
      <Helmet>
        <title>HelloAR: Augmented reality furniture| Furniture 3d Models</title>
        <meta
          name="description"
          content="We help you create furniture 3d models, 3D configurators, and allow VR/AR visulaization. Implement Augmented Reality in Furniture in  minutes with HelloAR"
        />
        <meta
          name="keywords"
          content="augmented reality furniture, furniture 3d models, 3d furniture configurator"
        />
        <meta httpEquiv="X-UA-Compatible" content="ie=edge" />
        <meta httpEquiv="Content-Type" content="text/html;charset=UTF-8" />
        <meta property="og:image" content="../../images/auto/car_360.jpg" />
      </Helmet>
      <Container fluid className="furniture">
        <div className="spacer"></div>
        <div className="spacer d-xl-none"></div>
        <div className="spacer-sm"></div>
        <div className="section-1">
          <CommonSection
            title="Augmented reality (AR) based furniture solutions"
            sub="Create stellar shopping experience for customers with our one stop AR/ VR platform."
            buttonText="REQUEST A DEMO"
          >
            <VideoPlayer
              videoUrl="../../../videos/ar-furniture-app.mp4"
              videoThumbnail="../../images/thumbnail/furniture.webp"
            />
          </CommonSection>
        </div>
        <div className="spacer-sm"></div>
        <div className="section-2">
          {/* <CaseStudy caseStudies={caseStudies} /> */}

          {/* <ClientLogoBanner logoList={logoList} /> */}
        </div>
        <div className="spacer" />
        <ClientLogoBanner logoList={logoList} />
        {/* <CaseStudy /> */}
        <div className="spacer" />
        <ButtonBanner buttonTitle="REQUEST A DEMO" buttonUrl="/contact-us">
          <h1 className="h1 text-center text-light px-3">
            One stop solution.
            <br />
            Zero upfront cost. Pay as you go.
          </h1>
        </ButtonBanner>

        <div className="spacer" />
        <StatColumn
          title="Why adopt augmented reality in furniture?"
          items={[
            {
              stat: "60%",
              info: "More than 60% of consumers want to shop for furniture with AR​",
            },
            {
              stat: "11x",
              info: "Customers are 11x more likely to buy when shopping with AR​",
            },
            {
              stat: "72%",
              info: "AR can lead 72% of shoppers to buy more than they planned",
            },
          ]}
        />
        <div className="spacer" />
        <Testimonials />
      </Container>
    </Layout>
  );
};

export default Furniture;
